*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@font-face {
    font-family: 'BudmoJiggler-Regular';
    src: url(../fonts/BudmoJiggler-Regular.eot);
    src: url('../fonts/BudmoJiggler-Regular.eot?#iefix') format('embedded-opentype'), url(../fonts/BudmoJiggler-Regular.woff2) format('woff2'), url(../fonts/BudmoJiggler-Regular.woff) format('woff'), url(../fonts/BudmoJiggler-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'NunitoRegular';
    src: url('../fonts/Nunito-Regular.eot');
    src: url('../fonts/Nunito-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Nunito-Regular.woff2') format('woff2'),
        url('../fonts/Nunito-Regular.woff') format('woff'),
        url('../fonts/Nunito-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NunitoBold';
    src: url('../fonts/Nunito-Bold.eot');
    src: url('../fonts/Nunito-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Nunito-Bold.woff2') format('woff2'),
        url('../fonts/Nunito-Bold.woff') format('woff'),
        url('../fonts/Nunito-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@-ms-viewport {
    width: device-width;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    margin: 0;
    font-family: 'NunitoRegular';
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3;
    color: #ffffff;
    text-align: left;
    background-color: #0b0212;
    overflow-x: hidden;
}

[tabindex="-1"]:focus {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    line-height: 1;
    font-weight: bold;
    font-family: 'NunitoBold';
}

h1 {
    font-family: 'BudmoJiggler-Regular';
    font-weight: 400;
}

a {
    text-decoration: none;
    color: #ffffff;

    &:hover {
        text-decoration: none;
        color: #ffffff;
    }
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

img {
    max-width: 100%;
    width: auto;
    height: auto;
}

.loaderMain {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        max-width: 200px;
    }
}

// Button Css
.btgGrp {
    margin-top: 30px;
}

.section-headline {
    padding-bottom: 90px;
    position: relative;
    margin-bottom: 30px;

    h3 {
        display: inline-block;
        font-size: 40px;
        font-weight: 700;
        text-transform: capitalize;
    }

    p {
        max-width: 650px;
        font-size: 16px;
        letter-spacing: 0.5px;
        margin: 0 auto;
    }

    &::before {
        content: "";
        left: 50%;
        position: absolute;
        width: 200px;
        z-index: 10;
        height: 40px;
        margin: 0 auto;
        margin-left: -100px;
        background: url(../images/line.png);
        background-repeat: no-repeat;
        bottom: 27px;
        filter: grayscale(1);
    }
}

.rainbow-btn {
    text-align: center;
    position: relative;
    color: #ffffff;
    text-decoration: none;
    border-radius: 3px;
    display: inline-block;
    overflow: hidden;
    padding: 3px;
    font-size: 20px;
    margin: 20px 10px 20px;
    min-width: 220px;

    &.bgblack {
        span {
            background-color: #0b0212;
        }
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 100%;
        background: linear-gradient(115deg, #d3a3ff, #ffa3a3, #a3b4ff, #a3fffa, #ffa3a3);
        animation: rainbow-btn .75s linear infinite;
        background-size: 50% 100%;
        overflow: hidden;
    }

    span {
        position: relative;
        z-index: 2;
        padding: 8px;
        background-color: #3e155c;
        border-radius: 3px;
        display: block;
    }

    &:hover {
        span {
            background-color: #d3a3ff;
        }

        &::before {
            background: linear-gradient(115deg, #d3a3ff, #d3a3ff, #d3a3ff, #d3a3ff, #d3a3ff);
        }
    }

    @keyframes rainbow-btn {
        to {
            transform: translateX(-50%)
        }
    }
}





/* Header Start */
header {
    display: block;
    text-align: center;
    padding: 0px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    mix-blend-mode: color-dodge;

    video,
    .logoMainHead {
        max-width: 250px;
        mix-blend-mode: color-dodge;
        margin: 50px auto 0px;
    }
}

/* Header End */
// Banner Section Start
.topBanner {
    min-height: calc(100vh + 180px);
    height: 990px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-image: url('../images/bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 0;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../images/bgshap.webp');
        background-size: contain;
        background-position: center;
        opacity: 1;
    }

    .container {
        position: relative;
        z-index: 1;
    }

    h1 {
        font-size: 62px;
        max-width: 900px;
        margin: 0 auto 30px;
        letter-spacing: 3px;
        color: #ffffff;
        text-shadow: 0 0 7px #0000, 0 0 0 #ffffff, 0 0 2px #ffffff, 0 0 2px #d9b0ff, 0 0 2px #d9b0ff, 0 0 0 #d9b0ff, 0 0 2px #d9b0ff, 0 0 11px #d9b0ff;
    }

    p {
        max-width: 900px;
        margin: 0 auto 30px;
    }

    // .bannerBg {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     height: 80%;
    //     object-fit: cover;
    //     z-index: 0;
    //     mix-blend-mode: lighten;
    //     width: 100%;
    //     animation: rotateImage 12s linear infinite;
    //     transform: scale(2);
    // }
    .bannerBg {
        position: absolute;
        top: 0;
        left: 0;
        height: 80%;
        object-fit: cover;
        z-index: 0;
        mix-blend-mode: color-burn;
        width: 100%;
    }

    @keyframes rotateImage {
        0% {
            transform: rotate(0deg) scale(2);
        }

        100% {
            transform: rotate(360deg) scale(2);
        }
    }

    .banenrAnimicon {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;

        &>img {
            animation: zoom-in-zoom-out 2s ease-out infinite;
        }

        .icon1 {
            position: absolute;
            top: 20%;
            left: 10%;
            max-width: 100px;
        }

        .icon2 {
            position: absolute;
            top: 20%;
            right: 10%;
            max-width: 100px;
        }

        .icon3 {
            position: absolute;
            bottom: 25%;
            left: 20%;
            max-width: 100px;
        }

        .icon4 {
            position: absolute;
            bottom: 18%;
            right: 20%;
            max-width: 100px;
        }
    }
}

.lazyload-wrapper,
.lazyload-wrapper>div,
.popular_gaming_thumb {
    pointer-events: none;
}

// Banner Section End
.aboutMain {
    position: relative;
    margin: 0px;
    overflow: hidden;

    .aboutInner {
        h2 {
            font-size: 40px;

            span {
                background: linear-gradient(to right, #a8cbff 20%, #caa8ff 40%, #caa8ff 60%, #a8cbff 80%);
                background-size: 200% auto;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: bold;
                animation: shine 1s linear infinite;
                position: relative;
                display: block;
                font-size: 82px;

                &::after {
                    content: "";
                    position: relative;
                    background-image: url('../images/1.webp');
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    display: inline-block;
                    width: 70px;
                    height: 70px;
                    top: -35px;
                    left: -18px;
                    animation: zoom-in-zoom-out-2 2s ease-out infinite;
                }

                @keyframes shine {
                    to {
                        background-position: 200% center;
                    }
                }
            }
        }

        strong {
            font-weight: 400;
            font-size: 24px;
        }
    }

    .aboutVideo {

        video,
        svg {
            transform: scale(1.2) !important;
            filter: hue-rotate(250deg);
            mix-blend-mode: lighten;
        }
    }
}

.broadBoxMain {
    padding: 0px;
    margin-top: -100px;

    .singleBlog {
        position: relative;
        padding: 2px;
        margin-bottom: 30px;
        border-radius: 6px;
        overflow: hidden;

        .singleBlogIn {
            width: 100%;
            height: 100%;
            z-index: 5;
            position: relative;
            display: flex;
            align-items: center;
            background-color: #230733;
            border-radius: 6px;
            padding: 30px 20px;
            background-image: url('../images/blogbgshap.png');
            background-size: auto;
            background-position: center bottom;
            background-repeat: no-repeat;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 200%;
            height: 100%;
            background: linear-gradient(115deg, #d3a3ff, #ffa3a3, #a3b4ff, #a3fffa, #ffa3a3);
            animation: rainbow-btn .75s linear infinite;
            background-size: 50% 100%;
            overflow: hidden;
        }
    }

    .imgBox {
        float: left;
        width: 36%;
        height: 100%;
        margin-right: 15px;
        border-radius: 20px;
        margin-left: 15px;

        img {
            height: auto;
            width: auto;
            max-height: 100px;
            animation: zoom-in-zoom-out-2 2s ease-out infinite;
        }
    }

    .txtNumber {
        font-size: 36px;
        font-weight: 700;
        color: #fff;
    }

    h6 {
        font-size: 13px;
        text-transform: uppercase;
        margin-bottom: 0px;
        position: relative;

        &::before {
            background: #0EC929;
        }
    }
}

.pdfDownloadMain {
    padding: 50px 0px;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../images/pdfdpwnload.png');
        z-index: -1;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        opacity: 0.1;
    }

    .pdfImage {
        text-align: center;
        position: relative;

        *[aria-label="animation"] {
            pointer-events: none;
            width: auto !important;
            height: auto !important;
        }

        svg {
            animation: zoom-in-zoom-out-2 2s ease-out infinite;
            max-width: 400px;
        }

        video {
            filter: invert(1);
            mix-blend-mode: color-dodge;
            max-width: 600px;
        }
    }

    .pdftxt {
        padding: 30px 20px;
        background-image: linear-gradient(to right, #556bb9, #8670c5);
        margin-bottom: 15px;
        border-radius: 6px;

        p {
            margin: 0px;
        }
    }
}

.nftMain {
    padding: 50px 0px;

    .popular_gaming_inner {
        .swiper-button-prev {
            background-color: #873787;
            cursor: pointer;
            height: 50px;
            left: 0;
            position: absolute;
            top: 45%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            width: 50px;
            z-index: 9999999;

            &::before {
                align-items: center;
                content: "↞";
                display: flex;
                font-size: 36px;
                font-weight: 600;
                height: 100%;
                justify-content: center;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }

        .swiper-button-next {
            background-color: #873787;
            cursor: pointer;
            height: 50px;
            position: absolute;
            right: 0;
            top: 45%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            width: 50px;
            z-index: 9999999;

            &::before {
                align-items: center;
                content: "↠";
                display: flex;
                font-size: 36px;
                font-weight: 600;
                height: 100%;
                justify-content: center;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }

        .nftsvideo {
            border: 1px solid #686868;
            border-radius: 10px;
            overflow: hidden;
            position: relative;
        }

        .nftboxtitle {
            align-items: center;
            background-color: #686868;
            color: #fff;
            display: flex;
            font-size: 14px;
            font-weight: 500;
            justify-content: center;
            left: -60px;
            padding: 7px 5px;
            position: absolute;
            text-align: center;
            top: 40px;
            -webkit-transform: rotate(-55deg);
            transform: rotate(-55deg);
            width: 200px;
            z-index: 1;
        }

        .swiper-slide {
            overflow: hidden;
        }
    }
}

// Faq's Section Start
.faqMain {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../images/faqback.jpg');
        z-index: -1;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        opacity: 0.07;
    }

    .faqImg {
        text-align: center;

        *[aria-label="animation"] {
            pointer-events: none;
            width: auto !important;
            height: auto !important;
        }

        svg {
            animation: zoom-in-zoom-out-2 2s ease-out infinite;
            max-width: 500px;
            pointer-events: none;
        }
    }

    .faq-row {
        margin: 5px 0 0px;
        border: none !important;
        padding: 0px !important;

        .row-title {
            padding: 15px !important;
            background-image: linear-gradient(to right, #6177c9, #8670c5);
            border-radius: 8px;
            margin: 0 0 5px;
        }
    }
}

// Footer Section Start
footer {
    position: relative;
    text-align: center;
    background-color: #020004;
    padding: 30px 0px;
    border-top: 1px solid #453f4a;
    margin-top: 100px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('../images/rotate.webp');
        background-size: auto;
        background-position: center bottom;
        background-repeat: no-repeat;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        opacity: 0.5;
    }

    .container {
        position: relative;
        z-index: 1;
    }

    ul {
        margin: 0px;
        padding: 0px;
        list-style: none;

        li {
            position: relative;

            a {
                font-size: 30px;
            }

            svg {
                fill: #a8cbff;
            }
        }
    }
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.2, 1.2);
    }

    100% {
        transform: scale(1, 1);
    }
}

@keyframes zoom-in-zoom-out-2 {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(0.9, 0.9);
    }

    100% {
        transform: scale(1, 1);
    }
}

// Footer Section End
// Media Query Start

@media only screen and (min-width:767px) {
    .popular_gaming_inner>.swiper .swiper-slide.swiper-slide-next {
        transform: scale(1.2);
        position: relative;
        z-index: 9
    }

    .popular_gaming_inner>.swiper {
        transform: scale(.95);
        padding-top: 60px;
        padding-bottom: 90px;
        padding-left: 30px;
        padding-right: 30px
    }
}

@media only screen and (max-width:767px) {
    .topBanner h1 {
        font-size: 48px;
    }

    .aboutMain {
        text-align: center;
    }

    .pdfDownloadMain {
        padding: 20px 0px;
    }

    .nftMain {
        padding: 20px 0px;
    }

    footer {
        margin-top: 50px;
    }

    .topBanner .banenrAnimicon {
        opacity: 0.2;
    }

    .topBanner {
        min-height: auto;
        padding-top: 240px;
        height: auto;

        .btgGrp {
            margin-top: 30px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            text-align: center;

            .rainbow-btn {
                font-size: 16px;
                margin: 10px 5px 10px;
                min-width: 170px;
            }
        }
    }

    .broadBoxMain {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 575px) {

    .popular_gaming_inner .swiper-button-next,
    .popular_gaming_inner .swiper-button-prev {
        height: 35px;
        width: 35px;
    }

    header video {
        max-width: 180px;
        margin-top: 20px;
    }

    .topBanner h1 {
        font-size: 32px;
    }

    .topBanner .banenrAnimicon .icon1 {
        top: 13%;
        max-width: 50px;
    }

    .topBanner .banenrAnimicon .icon2 {
        top: 15%;
        max-width: 50px;
    }

    .topBanner .banenrAnimicon .icon3 {
        bottom: 25%;
        left: 10%;
        max-width: 50px;
    }

    .topBanner .banenrAnimicon .icon4 {
        bottom: 30%;
        right: 0%;
        max-width: 50px;
    }

    .broadBoxMain {
        padding: 0px;
        margin-top: 40px;
    }

    .aboutMain .aboutInner h2 span {
        font-size: 60px;

        &::after {
            display: inline-block;
            width: 40px;
            height: 40px;
        }
    }

    .topBanner {
        height: auto;
    }
}

@media only screen and (max-width: 390px) {
    .topBanner h1 {
        font-size: 24px;
    }

    .topBanner .btgGrp .rainbow-btn {
        font-size: 16px;
        min-width: auto;
    }

    .section-headline h3 {
        font-size: 32px;
    }
}

// Media Query End